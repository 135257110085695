<!-- @Author: Yu_Bo -->
<template>
  <div>
    <div class='book_video zc_dialog_box'>
      <el-dialog :visible.sync="dialogVisible" width="640px" :close-on-click-modal='false'>
        <div class="title" slot="title">
          <div class="name">视频转台本</div>
        </div>
        <div class="url_main">
          <div class="main_text" v-if="!video_file">
            <el-upload class="upload-demo" drag action="#" :show-file-list="false" :http-request="uploadimg">
              <i class="el-icon-upload"></i>
              <div class="text">请<span>点击上传</span>MP4视频文件</div>
            </el-upload>
          </div>
          <div class="main_mp" v-else>
            <span>{{video_file.name}}</span>
            <el-upload action="#" :show-file-list="false" :http-request="uploadimg">
              <el-button class="btnBgColor_blue" size="small" type="primary">重新上传</el-button>
            </el-upload>
          </div>
          <div class="main_tip">注：请上传50M以内的MP4文件</div>
          <div class="main_btn">
            <div class="btn_left">
              <el-radio v-model="radio" :label="1">提取文本</el-radio>
              <el-radio v-model="radio" :label="2">提取声音</el-radio>
            </div>
            <div class="btn_right">
              <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
              <el-button class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn">确 定</el-button>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 加载中 -->
    <book-loading ref="loadingBook" type='video_link' @cancel='cancelLoadingBtn'></book-loading>
    <!--  -->
    <point-out ref='dialogTip' dialogTitle="提示" dialogWidth='380px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='false' :affirmShow='true'>
      <div class="zc_dialog_tip_content">
        <div class="text">视频地址获取失败，请稍等重试。</div>
      </div>
    </point-out>
  </div>
</template>

<script>
	import BookLoading from '@/components/book_page/book_loading.vue'//加载
  import PointOut from '@/components/point_out/point_out.vue'//提示
	export default {
		components: {
	    BookLoading,
      PointOut
	  },
		props: {},
		data() {
			return{
        is_type: ['video/mp4'],
				dialogVisible:false,
        video_file:null,
        radio:null,
        typeShow:false,
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开
      openDialog() {
        this.typeShow=false
        this.dialogVisible = true
      },
      // 上传
      uploadimg(item){
        var that = this
        const isVIDEO = that.is_type.includes(item.file.type)
        const isLt5M = item.file.size / 1024 / 1024 <= 50
        if (!isVIDEO) {
            that.$warMsg('上传视频只能是 MP4 格式!')
        }
        if (!isLt5M) {
            that.$warMsg('上传视频大小不能超过 50MB!')
        }
        if(isVIDEO && isLt5M){
          that.video_file=item.file
        }
      },
      // 取消
      cancelBtn(){
        this.video_file = null,
        this.radio = null,
        this.typeShow = false,
        this.dialogVisible = false
      },
      // 确定
      affirmBtn(){
        var that = this
        if(!that.video_file){
          that.$warMsg('请上传MP4视频文件，视频大小不能超过 50MB!')
        }else if(!that.radio){
          that.$warMsg('请选择转换类型')
        }else{
          that.$refs.loadingBook.openDialog()
          let newFile = new FormData()
          newFile.append('type', that.radio)
          newFile.append('file', that.video_file)
          that.$workbenApi.videoConvert(newFile).then(res => {
            if (res.code == 1000) {
              if(that.typeShow){
                // 不跳转
                that.typeShow=false
                return
              }else{
                // 跳转
                that.$refs.loadingBook.closeBtn()
                var info = {
                  type:that.radio,
                  val:that.radio==1?res.result.texts:res.result.sound
                }
                that.$store.commit('SET_BOOK',info)
                that.$router.push('/workben/drama/-3')
                that.cancelBtn()
              }
            } else {
              that.$refs.dialogTip.openDialogBtn()
              // that.$errMsg(res.message)
            }
          })
        }
      },
      // 取消
      cancelLoadingBtn(val){
        this.typeShow=true
      }
    },
	}
</script>

<style lang='scss' scoped>
	.book_video{
    .url_main{
      width: 100%;
      .main_text{
        cursor: pointer;
        width: 100%;
        height: 130px;
        .upload-demo{
          width: 100%;
          height: 100%;
        }
        ::v-deep .el-upload{
          width: 100%;
          height: 100%;
          .el-upload-dragger{
            width: 100%;
            height: 100%;
            .el-icon-upload{
              margin-top: 20px;
            }
          }
        }
        .text{
          width: 100%;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          span{
            color: #3753F2;
          }
        }
      }
      .main_mp{
        width: 100%;
        display: flex;
        align-items: center;
        span{
          padding-right: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
      .main_tip{
        width: 100%;
        padding-top: 10px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .main_btn{
        width: 100%;
        padding-top: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn_left{
          display: flex;
          align-items: center;
        }
        .btn_right{
          display: flex;
          align-items: center;
        }
      }
    }
	}
</style>
